import React, { useState, useEffect, useCallback } from "react";
import "./teste.css";
import whats from "../../img/whatsapp.svg";

const Teste = ({ isOpen, onClose }) => {
  const handleClose = useCallback(() => {
    onClose();
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && event.target.className === "T-modal-overlay") {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, handleClose]);

  return isOpen ? (
    <div className="T-modal-overlay">
      <div className="T-modal-content">
        <span className="T-close" onClick={handleClose}>
          &times;
        </span>
        <h2 className="T-modal-title">Teste de Nivelamento</h2>
        <p className="T-modal-description">
          Descubra seu nível, preencha o teste de nivelamento e envie para nós.
          Assim, uma das professoras entrará em contato com uma devolutiva.
        </p>
        <a
          href="https://drive.google.com/file/d/1MF2PD25t3U58pOcSGg72i-f2OngLfdch/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
          className="T-download-link"
          style={{ textDecoration: "none" }}
        >
          <button
            className="T-download-button"
            style={{ textDecoration: "none" }}
          >
            Baixar teste de nivelamento
          </button>
        </a>
        <a
          href="https://wa.me/554788269124?text=Ol%C3%A1%21+Estou+interessado%28a%29+nos+servi%C3%A7os+disponibilizados+pelo+site%2C+poderias+me+enviar+mais+detalhes%3F"
          target="_blank"
          rel="noopener noreferrer"
          className="T-whatsapp-link"
          style={{ textDecoration: "none" }}
        >
          <button className="contact-button">
            <img loading="lazy" src={whats} className="CTA-planos-img" />
            Entrar em contato
          </button>
        </a>
      </div>
    </div>
  ) : null;
};

export default Teste;
