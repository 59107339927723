import React from "react";
import Slider from "../slider/slider";

export const scrollToDepoimentos = () => {
  const depoir = document.querySelector(".depoir");
  if (depoir) {
    const offset = -90;
    const targetPosition =
      depoir.getBoundingClientRect().top + window.scrollY + offset;

    const scrollTo = (duration, target) => {
      const start = window.pageYOffset;
      const distance = target - start;
      const startTime = performance.now();

      const animateScroll = (nowTime) => {
        const timeElapsed = nowTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        window.scrollTo(0, start + distance * progress);
        if (timeElapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    };

    scrollTo(500, targetPosition); // Tempo de duração da animação em milissegundos
  }
};

function Depoimentos() {
  return (
    <div className="dep-container depoir">
      <div className="nos-content">
        <div className="nos-title" style={{ paddingBottom: "22px" }}>
          Depoimentos
        </div>
        <Slider />
      </div>
    </div>
  );
}

export default Depoimentos;
